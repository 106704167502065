import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { Suspense, useState } from 'react';
import { Button, Popover } from '~/components';
import { DefaultErrorComponent, ErrorBoundary, } from '~/components/ErrorBoundary';
import { BellIcon } from '~/icons';
import { NotificationManagement } from '../NotifiationManagement';
const NotificationElement = () => {
    const [notificationsOpen, setNotificationsOpen] = useState(false);
    return (_jsxs(_Fragment, { children: [_jsxs(Popover, { children: [_jsxs(Popover.Button, Object.assign({ color: "ghost" }, { children: [_jsx(BellIcon, {}), _jsx("span", Object.assign({ className: "sr-only" }, { children: "Notifications" }))] })), _jsx(Popover.Panel, Object.assign({ className: "notification-panel" }, { children: _jsxs(ErrorBoundary, Object.assign({ FallbackComponent: DefaultErrorComponent }, { children: [_jsx("div", Object.assign({ className: "notification-panel__header" }, { children: _jsx("h3", Object.assign({ className: "heading--h4 heading--no-transform" }, { children: "Notifications" })) })), _jsx("div", Object.assign({ className: "notification-panel__content" }, { children: _jsx("p", Object.assign({ className: "text--t3" }, { children: "You have no new notifications" })) })), _jsxs("div", Object.assign({ className: "notification-panel__footer" }, { children: [_jsx(Button, Object.assign({ color: "ghost-link", disabled: true }, { children: "Mark all as read" })), _jsx(Button, Object.assign({ color: "ghost-link", onClick: () => setNotificationsOpen(true) }, { children: "See search alerts" }))] }))] })) }))] }), notificationsOpen && (_jsx(ErrorBoundary, Object.assign({ FallbackComponent: DefaultErrorComponent }, { children: _jsx(Suspense, Object.assign({ fallback: null }, { children: _jsx(NotificationManagement, { open: notificationsOpen, onClose: () => setNotificationsOpen(false) }) })) })))] }));
};
export default NotificationElement;
