import { clsx } from 'clsx';
/**
 * User defined type guard, which guarantees "object is T", not undefined, not null
 * @param {T | undefined | null} variable
 * @returns {object is T}
 */
export const isDefined = (variable) => variable !== undefined && variable !== null;
/**
 * Type guard, which guarantees object is defined but empty
 * @param {"" | T | undefined | null} value
 * @returns {value is "" | undefined | null}
 */
export const isEmpty = (value) => !isDefined(value) || value === '';
/**
 * No-op function
 * @returns {void}
 */
export const noop = () => { };
/**
 * Create slug from text
 * @param {string} text
 * @returns {string}
 * @example
 * const slug = slugify('Hello World'); // hello-world
 */
export const slugify = (text) => {
    return text
        .toLowerCase()
        .replace(/[^a-zA-Z0-9]+/g, '-') // Replace non-alphanumeric characters with hyphens
        .replace(/^-+|-+$/g, '') // Remove leading/trailing hyphens
        .trim(); // Remove leading/trailing whitespace
};
/**
 * Return array of specified length
 * @param {number} length
 * @returns {number[]}
 * @example
 * const arr = arrayOf(5); // [0, 1, 2, 3, 4]
 */
export const arrayOf = (length) => Array.from(Array(length).keys());
/**
 * Debounce function
 * @param {F} delayedFunc - function to be executed after delay
 * @param {number} delay - delay in milliseconds
 * @returns {(...args: Parameters<F>) => void}
 * @example
 * const delayedFunc = debounce(() => console.log('Hello'), 1000);
 * delayedFunc(); // Will be executed after 1 second
 */
export const debounce = (delayedFunc, delay) => {
    let timeoutId;
    return (...args) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            delayedFunc(...args);
        }, delay);
    };
};
/**
 * Retrieves and parses a data attribute with a specified type.
 *
 * @param {HTMLElement} element - The element containing the data attribute.
 * @param {string} attributeName - The name of the data attribute.
 * @param {'number' | 'string' | 'boolean'} dataType - The expected data type.
 * @throws {Error} If the attribute is missing or not a valid value of the specified type.
 * @returns {number | string | boolean} The parsed value with the specified type.
 * @example
 * const element = document.querySelector('.element');
 * const value = parseDataAttribute<number>(element, 'attribute-name', 'number');
 * console.log(value); // 123
 * @example
 * const element = document.querySelector('.element');
 * const value = parseDataAttribute<string>(element, 'attribute-name', 'string');
 * console.log(value); // 'Hello World'
 * @example
 * const element = document.querySelector('.element');
 * const value = parseDataAttribute<boolean>(element, 'attribute-name', 'boolean');
 * console.log(value); // true
 */
export const parseDataAttribute = (element, attributeName, dataType) => {
    const attributeValue = element.getAttribute(`data-${attributeName}`);
    if (attributeValue === 'null' || attributeValue === null) {
        throw new Error(`Missing ${attributeName} value`);
    }
    switch (dataType) {
        case 'number':
            if (!isNaN(parseInt(attributeValue, 10))) {
                return parseInt(attributeValue, 10);
            }
            throw new Error(`Invalid ${attributeName} value for number`);
        case 'string':
            return attributeValue;
        case 'boolean':
            if (attributeValue === 'true') {
                return true;
            }
            else if (attributeValue === 'false') {
                return false;
            }
            throw new Error(`Invalid ${attributeName} value for boolean`);
        default:
            throw new Error(`Invalid data type: ${dataType}`);
    }
};
/**
 * Class names utility
 * Merge class names together and remove falsy values
 * @param {...ClassValue} classes
 * @returns {string}
 * @example
 * // Strings (variadic)
 * clsx('foo', true && 'bar', 'baz');
 * //=> 'foo bar baz'
 *
 * // Objects
 * clsx({ foo:true, bar:false, baz:isTrue() });
 * //=> 'foo baz'
 *
 * // Objects (variadic)
 * clsx({ foo:true }, { bar:false }, null, { '--foobar':'hello' });
 * //=> 'foo --foobar'
 *
 * // Arrays
 * clsx(['foo', 0, false, 'bar']);
 * //=> 'foo bar'
 *
 * // Arrays (variadic)
 * clsx(['foo'], ['', 0, false, 'bar'], [['baz', [['hello'], 'there']]]);
 * //=> 'foo bar baz hello there'
 *
 * // Kitchen sink (with nesting)
 * clsx('foo', [1 && 'bar', { baz:false, bat:null }, ['hello', ['world']]], 'cya');
 * //=> 'foo bar hello world cya'
 *
 *
 * @see https://www.npmjs.com/package/clsx
 */
export const cn = (...classes) => {
    return clsx(classes);
};
