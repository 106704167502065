import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from '@tanstack/react-query';
import { getSavedFilters } from '~/actions/searchAlert.actions';
import { Button, Dialog } from '~/components';
import { SearchAlertCard } from '~/components/SearchAlertCard';
const NotificationManagement = ({ onClose, open }) => {
    const { data } = useQuery({
        queryKey: ['savedFilters'],
        queryFn: getSavedFilters,
    });
    return (_jsxs(Dialog, Object.assign({ width: "sm", open: open, onClose: onClose }, { children: [_jsx(Dialog.Header, { children: _jsx("h2", { children: "Search Alerts" }) }), _jsx(Dialog.Body, Object.assign({ className: "d-flex flex-column" }, { children: data &&
                    data.map((alert) => (_jsx(SearchAlertCard, { searchAlert: alert }, alert.id))) })), _jsx(Dialog.Footer, { children: _jsx(Button, Object.assign({ color: "ghost-link" }, { children: "Create new search alert" })) })] })));
};
export default NotificationManagement;
