import { jsx as _jsx } from "react/jsx-runtime";
import { NotificationElement, SearchPage, SubcategoryPage, } from './MarketOpportunities/pages';
import { DOMInjector, parseDataAttribute } from './utils';
/**
 * Injects the SearchPage component into the 'bf-react-mo-landing-page' element.
 */
const searchPageInjector = document.getElementById('bf-react-mo-landing-page');
if (searchPageInjector) {
    /**
     * Retrieves the preload count attribute.
     * @type {number | Error}
     */
    const preloadCountNumber = parseDataAttribute(searchPageInjector, 'preload-count', 'number');
    /**
     * Retrieves the sections attribute.
     * @type {string | Error}
     */
    const sections = parseDataAttribute(searchPageInjector, 'sections', 'string');
    /**
     * Retrieves the data URL attribute.
     * @type {string | Error}
     */
    const currentURL = parseDataAttribute(searchPageInjector, 'current-url', 'string');
    /**
     * Retrieves the data URL attribute.
     * @type {number | Error}
     */
    const maxLoadCount = parseDataAttribute(searchPageInjector, 'max-load-count', 'number');
    /**
     * Injects the SearchPage component into the DOM.
     */
    DOMInjector({
        /**
         * The React component wrapped in a QueryClientProvider.
         * @type {React.ReactNode}
         */
        reactComponent: (_jsx(SearchPage, { preloadCount: preloadCountNumber, sections: JSON.parse(sections !== null && sections !== void 0 ? sections : '[]'), maxLoadCount: maxLoadCount }, 'bf-react-mo-landing-page')),
        /**
         * The DOM element where the component is injected.
         * @type {Object}
         */
        DOMElement: {
            id: 'bf-react-mo-landing-page',
        },
        /**
         * The path where the component is injected.
         * @type {string}
         */
        path: currentURL,
    });
}
/**
 * Injects the SubcategoryPage component into the 'bf-react-mo-section-page' element.
 */
const subcategoryPageInjector = document.getElementById('bf-react-mo-section-page');
if (subcategoryPageInjector) {
    // data-icon-text="@Model.CurrentContent.IconText"
    /**
     * Retrieves the preload content count attribute.
     * @type {number | Error}
     */
    const preloadCount = parseDataAttribute(subcategoryPageInjector, 'preload-count', 'number');
    /**
     * Retrieves the page name attribute.
     * @type {string | Error}
     */
    const pageName = parseDataAttribute(subcategoryPageInjector, 'page-name', 'string');
    /**
     * Retrieves the content types attribute.
     * @type {string | Error}
     */
    const contentTypes = parseDataAttribute(subcategoryPageInjector, 'content-types', 'string');
    /**
     * Retrieves the data URL attribute.
     * @type {string | Error}
     */
    const currentUrl = parseDataAttribute(subcategoryPageInjector, 'current-url', 'string');
    /**
     * Retrieves the landing page URL attribute.
     * @type {string | Error}
     */
    const landingPageUrl = parseDataAttribute(subcategoryPageInjector, 'landing-page-url', 'string');
    const iconText = parseDataAttribute(subcategoryPageInjector, 'icon-text', 'string');
    /**
     * Injects the SubcategoryPage component into the DOM.
     */
    DOMInjector({
        /**
         * The React component wrapped in a QueryClientProvider.
         * @type {React.ReactNode}
         */
        reactComponent: (_jsx(SubcategoryPage, { preloadCount: preloadCount, pageName: pageName, contentTypes: JSON.parse(contentTypes !== null && contentTypes !== void 0 ? contentTypes : '[]'), landingPageUrl: landingPageUrl, iconText: iconText })),
        /**
         * The DOM element where the component is injected.
         * @type {Object}
         */
        DOMElement: {
            id: 'bf-react-mo-section-page',
        },
        /**
         * The path where the component is injected.
         * @type {string}
         */
        path: currentUrl !== null && currentUrl !== void 0 ? currentUrl : '',
    });
}
/**
 * Injects the SubcategoryPage component into the 'bf-react-mo-section-page' element.
 */
const notificationsInjector = document.getElementById('bf-react-notification-element');
if (notificationsInjector) {
    DOMInjector({
        DOMElement: notificationsInjector,
        reactComponent: _jsx(NotificationElement, {}),
        Options: {
            useErrorBoundary: true,
        },
    });
}
