'use client';
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ErrorBoundary as ReactErrorBoundary, useErrorBoundary, } from 'react-error-boundary';
const ErrorBoundary = (_a) => {
    var { children } = _a, props = __rest(_a, ["children"]);
    return (_jsx(ReactErrorBoundary, Object.assign({}, props, { children: children })));
};
export default ErrorBoundary;
export const DefaultErrorComponent = ({ error, resetErrorBoundary, }) => {
    console.error(error);
    return (_jsxs("div", Object.assign({ className: "error-boundary" }, { children: [_jsx("h4", { children: "Sorry, we had trouble loading the content" }), _jsx("button", Object.assign({ type: "reset", onClick: resetErrorBoundary, className: "btn btn--secondary" }, { children: "Try to reload page" }))] })));
};
export const SubcategoryErrorComponent = ({ title }) => {
    const { resetBoundary } = useErrorBoundary();
    return (_jsxs(_Fragment, { children: [_jsx("h2", { children: title }), _jsx(DefaultErrorComponent, { error: 'An error occurred while fetching data.', resetErrorBoundary: () => resetBoundary() })] }));
};
