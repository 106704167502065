/**
 * apiClient
 * This function is used to make API calls.
 * @param input - RequestInfo
 * @param init - CustomInitType -> RequestInit
 * @returns Promise<T>
 * @throws Error
 * @example
 * ```ts
 * const data = await apiClient<DataType>('https://api.example.com/data');
 * > data: DataType | Error
 * ```
 */
export async function apiClient(input, init) {
    try {
        const res = await fetch(input, init);
        if (!res.ok) {
            throw new Error(`[API Client] Error while fetching data from API: ${res.statusText}`);
        }
        // Todo: What to do when status is 401
        if (res.status === 401) {
            return {};
        }
        // When status is 204, return empty object
        if (res.status === 204) {
            return {};
        }
        return res.json();
    }
    catch (error) {
        console.error(error);
        // If error is an instance of Error, throw the error message
        if (error instanceof Error) {
            throw new Error(`[API Client] Error while fetching data from API: ${error.message}`);
        }
        else {
            throw new Error(`[API Client] Error while fetching data from API: ${error}`);
        }
    }
}
