import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions, } from '@headlessui/react';
import { useEffect, useState } from 'react';
import { ChevronIcon, LoadingIcon } from '~/icons';
import { isDefined, isEmpty } from '~/utils';
function customSort(a, b) {
    if (a.count === undefined || a.count === 0) {
        return 1; // Move 'a' to the bottom
    }
    else if (b.count === undefined || b.count === 0) {
        return -1; // Move 'b' to the bottom
    }
    else {
        // Compare by label
        return a.label.localeCompare(b.label);
    }
}
const ListboxAutocomplete = ({ options, emptyOption, selected, setSelected, isLoading, disabled, }) => {
    var _a;
    const [query, setQuery] = useState('');
    const filteredOptions = query === ''
        ? options
        : options.filter((option) => option.label.toLowerCase().startsWith(query.toLowerCase()));
    filteredOptions.sort(customSort);
    useEffect(() => {
        let selectedOption = undefined;
        // If the selected value is not empty and the label is empty, find the option (if the option is set in search params with loading the page)
        if (selected.value !== undefined && isEmpty(selected.label)) {
            selectedOption = options.find((option) => option.value === selected.value);
            if (selectedOption) {
                setSelected(selectedOption);
            }
        }
        return () => {
            // The cleanup function should stop or undo what the setup function above does.
            // See more: https://react.dev/reference/react/useEffect#caveats
            selectedOption = undefined;
        };
        // Disabling because it wants the setSelected function to be a dependency and that could lead to endless rendering
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options, selected]);
    return (_jsx("div", Object.assign({ className: `headlessui-listbox ${disabled || isLoading ? 'headlessui-listbox--disabled' : ''}` }, { children: _jsx(Combobox, Object.assign({ value: selected, onChange: (e) => {
                setSelected(e);
                setQuery('');
            }, disabled: disabled || isLoading }, { children: _jsxs("div", Object.assign({ className: "headlessui-listbox-wrapper" }, { children: [_jsx("div", Object.assign({ className: "headlessui-listbox-wrapper" }, { children: _jsxs(ComboboxButton, Object.assign({ as: "div", className: "headlessui-listbox__button-autocomplete" }, { children: [_jsx(ComboboxInput, { onChange: (event) => setQuery(event.target.value), className: "headlessui-listbox__button", placeholder: (_a = emptyOption === null || emptyOption === void 0 ? void 0 : emptyOption.label) !== null && _a !== void 0 ? _a : 'Select an option', displayValue: (value) => value.label }), _jsx("span", Object.assign({ className: "headlessui-listbox__button-autocomplete--icon" }, { children: isLoading ? (_jsx(LoadingIcon, { className: "headlessui-listbox__button-autocomplete--icon--loading" })) : (_jsx(ChevronIcon, {})) }))] })) })), _jsxs(ComboboxOptions, Object.assign({ transition: true, className: "headlessui-listbox__options headless-transition" }, { children: [emptyOption && !isEmpty(selected.value) && (_jsxs(ComboboxOption, Object.assign({ className: "headlessui-listbox__option headlessui-listbox__option--clear-selection", value: { value: undefined, label: '' } }, { children: [_jsx("span", { children: "Remove selection" }), _jsx("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", fill: "none", viewBox: "0 0 24 24", strokeWidth: "1.5", stroke: "currentColor" }, { children: _jsx("path", { strokeLinecap: "round", strokeLinejoin: "round", d: "M6 18L18 6M6 6l12 12" }) }))] }))), filteredOptions.map((option, key) => (_jsx(ComboboxOption, Object.assign({ className: ({ selected, active }) => `headlessui-listbox__option ${selected ? 'headlessui-listbox__option--selected' : ''} ${option.disabled
                                    ? 'headlessui-listbox__option--disabled'
                                    : ''} ${active ? 'headlessui-listbox__option--active' : ''}`, value: { value: option.value, label: option.label } }, { children: `${option.label}${!isDefined(option.count) || option.count === 0
                                    ? ` (${option.count} results)`
                                    : ''}` }), `${option.label}-${key}`))), filteredOptions.length === 0 && (_jsx(ComboboxOption, Object.assign({ className: "headlessui-listbox__option headlessui-listbox__option--disabled", disabled: true, value: emptyOption }, { children: "No results found" })))] }))] })) })) })));
};
export default ListboxAutocomplete;
