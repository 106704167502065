var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Popover as PopoverUI, PopoverButton as PopoverUIButton, PopoverPanel as PopoverUIPanel, } from '@headlessui/react';
import { cn } from '~/utils';
import { Button } from '../Button';
// The className props actually exist in the PopoverProps type, but TypeScript doesn't know that
// eslint-disable-next-line react/prop-types
const Popover = (_a) => {
    var { className } = _a, props = __rest(_a, ["className"]);
    return (_jsx(PopoverUI, Object.assign({}, props, { className: cn('headlessui-popover', className) })));
};
export const PopoverButton = (_a) => {
    var { className } = _a, props = __rest(_a, ["className"]);
    return (_jsx(PopoverUIButton, Object.assign({ as: Button }, props, { className: cn('headlessui-popover__button', className) })));
};
export const PopoverPanel = (_a) => {
    var { anchor = 'bottom', transition = true, className } = _a, props = __rest(_a, ["anchor", "transition", "className"]);
    return (_jsx(PopoverUIPanel, Object.assign({}, props, { transition: transition, anchor: anchor, className: cn('headlessui-popover__panel', className) })));
};
Popover.Button = PopoverButton;
Popover.Panel = PopoverPanel;
export default Popover;
