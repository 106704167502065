import { cva } from 'class-variance-authority';
export const buttonClasses = cva(['btn'], {
    variants: {
        color: {
            primary: 'btn--primary',
            secondary: 'btn--secondary',
            ghost: 'btn--ghost',
            'ghost-link': 'btn--ghost-link',
        },
        variant: {
            button: '',
            icon: 'btn__icon',
        },
    },
    compoundVariants: [
        {
            color: 'ghost-link',
            className: 'text--bold text--t2',
        },
    ],
    defaultVariants: {
        color: 'primary',
    },
});
const BUTTON_TYPE = ['button', 'submit', 'reset'];
