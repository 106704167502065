var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { toast } from 'sonner';
import { deleteSearchAlert } from '~/actions/searchAlert.actions';
import { PencilIcon, TrashIcon } from '~/icons';
import { cn, isDefined, isEmpty } from '~/utils';
import { Button } from '../Button';
import EditSearchAlertDialog from './EditSearchAlertDialog';
const buildQueryParams = (searchAlert) => {
    const params = new URLSearchParams();
    if (searchAlert.term) {
        params.set('q', searchAlert.term);
    }
    if (searchAlert.industry) {
        params.set('industry', searchAlert.industry);
    }
    if (searchAlert.country) {
        params.set('country', searchAlert.country);
    }
    return params.toString();
};
function SearchAlertCard(_a) {
    var { searchAlert, className } = _a, props = __rest(_a, ["searchAlert", "className"]);
    const [editDialogOpen, setEditDialogOpen] = useState(null);
    const queryClient = useQueryClient();
    const deleteSearchAlertMutation = useMutation({
        mutationFn: deleteSearchAlert,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['savedFilters'] });
            toast.success('Search alert deleted');
        },
    });
    return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({}, props, { className: cn('search-alert-card', className) }, { children: [_jsxs("div", Object.assign({ className: "search-alert-card__content" }, { children: [_jsx("h3", Object.assign({ className: "search-alert-card__title heading--no-transform" }, { children: searchAlert.name })), _jsx("p", Object.assign({ className: "search-alert-card__query-item" }, { children: `Search term: ${isEmpty(searchAlert.query.term) ? '-' : searchAlert.query.term}` })), _jsx("p", Object.assign({ className: "search-alert-card__query-item" }, { children: `Industry: ${isEmpty(searchAlert.query.industry) ? '-' : searchAlert.query.industry}` })), _jsx("p", Object.assign({ className: "search-alert-card__query-item" }, { children: `Country: ${isEmpty(searchAlert.query.country) ? '-' : searchAlert.query.country}` }))] })), _jsxs("div", Object.assign({ className: "search-alert-card__actions" }, { children: [_jsxs("div", Object.assign({ className: "search-alert-card__actions__buttons" }, { children: [_jsxs(Button, Object.assign({ color: "ghost", onClick: () => setEditDialogOpen(searchAlert) }, { children: [_jsx(PencilIcon, {}), _jsx("span", Object.assign({ className: "sr-only" }, { children: "Edit" }))] })), _jsxs(Button, Object.assign({ color: "ghost", onClick: () => deleteSearchAlertMutation.mutate({ id: searchAlert.id }) }, { children: [_jsx(TrashIcon, {}), _jsx("span", Object.assign({ className: "sr-only" }, { children: "Delete" }))] }))] })), _jsx("a", Object.assign({ href: `?${buildQueryParams(searchAlert.query)}`, className: "text--bold text--t2" }, { children: "See search results" }))] }))] })), isDefined(editDialogOpen) && (_jsx(EditSearchAlertDialog, { open: isDefined(editDialogOpen), onClose: () => setEditDialogOpen(null) }))] }));
}
/**
 * @component SearchAlertCard
 * @description A card that displays an search alert
 * @default
 */
export default SearchAlertCard;
