import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMutation, useQuery } from '@tanstack/react-query';
import { Suspense, useState, } from 'react';
import { isEmpty } from '~/utils';
import { Link, useSearchParams } from 'react-router-dom';
import { Button, Listbox } from '~/components';
import { DefaultErrorComponent, ErrorBoundary, SubcategoryErrorComponent, } from '~/components/ErrorBoundary';
import Searchbar from '~/components/Searchbar/Searchbar';
import { toast } from 'sonner';
import { getFilterData } from '~/actions/opportunity.actions';
import { postSaveSearch } from '~/actions/searchAlert.actions';
import { BellIcon, XMark } from '~/icons';
import { NotificationManagement } from '../NotifiationManagement';
import Subcategory from './components/Subcategory';
const SEARCH_ALERT_TOAST_ID = 'searchAlertId';
const getSearhAlertName = (industry, country) => {
    if (isEmpty(industry) && isEmpty(country)) {
        return 'New search alert';
    }
    if (isEmpty(industry)) {
        return `Alert for ${country}`;
    }
    if (isEmpty(country)) {
        return `Alert for ${industry}`;
    }
    return `${industry} in ${country}`;
};
const SearchPage = ({ preloadCount = 10, sections, maxLoadCount = 10, }) => {
    var _a, _b, _c, _d, _e, _f;
    const [notificationsOpen, setNotificationsOpen] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [searchQuery, setSearchQuery] = useState((_a = searchParams.get('q')) !== null && _a !== void 0 ? _a : '');
    const [searchItem, setSearchItem] = useState({ id: undefined, name: (_b = searchParams.get('q')) !== null && _b !== void 0 ? _b : '' });
    const countrySearchParam = (_c = searchParams.get('country')) !== null && _c !== void 0 ? _c : '';
    const [country, setCountry] = useState(isEmpty(countrySearchParam)
        ? {
            label: '',
            value: undefined,
        }
        : {
            label: '',
            value: Number(countrySearchParam),
        });
    const industrySearchParam = (_d = searchParams.get('industry')) !== null && _d !== void 0 ? _d : '';
    const [industry, setIndustry] = useState(isEmpty(industrySearchParam)
        ? {
            label: '',
            value: undefined,
        }
        : {
            label: '',
            value: Number(industrySearchParam),
        });
    const { data: filtersData, isLoading: isFiltersLoading, isFetching: isFiltersFetching, } = useQuery({
        queryKey: ['filters', country.value, industry.value, searchItem === null || searchItem === void 0 ? void 0 : searchItem.name],
        queryFn: () => getFilterData({
            contentTypeIds,
            searchItem: searchItem.name,
            country: country.value,
            industry: industry.value,
        }),
    });
    const contentTypeIds = sections && sections.map((sections) => sections.contentTypes).flat();
    const handleFilterChange = (fn, option, searchParamName) => {
        setSearchParams((prev) => {
            var _a, _b;
            const params = new URLSearchParams(prev);
            params.set(searchParamName, (_b = (_a = option.value) === null || _a === void 0 ? void 0 : _a.toString()) !== null && _b !== void 0 ? _b : '');
            return params;
        });
        fn(option);
    };
    const saveSearchMutation = useMutation({
        mutationFn: postSaveSearch,
        onSuccess: () => {
            toast('Your search alert has been created', {
                icon: _jsx(BellIcon, { className: "sa-toast__icon" }),
                description: (_jsx(Button, Object.assign({ color: "ghost-link", onClick: () => {
                        setNotificationsOpen(true);
                        toast.dismiss(SEARCH_ALERT_TOAST_ID);
                    } }, { children: "Manage search alerts" }))),
                id: SEARCH_ALERT_TOAST_ID,
                action: (_jsx(Button, Object.assign({ variant: "icon", color: "ghost", onClick: () => toast.dismiss(SEARCH_ALERT_TOAST_ID) }, { children: _jsx(XMark, {}) }))),
            });
        },
        onError: (error) => {
            toast.error('Could not save search', {
                description: error.message,
            });
        },
    });
    const handleSave = async () => {
        var _a, _b;
        saveSearchMutation.mutateAsync({
            name: getSearhAlertName(industry.label, country.label),
            query: {
                term: searchItem.name,
                contentTypes: contentTypeIds,
                country: (_a = country === null || country === void 0 ? void 0 : country.value) !== null && _a !== void 0 ? _a : undefined,
                industry: (_b = industry === null || industry === void 0 ? void 0 : industry.value) !== null && _b !== void 0 ? _b : undefined,
            },
            alertFrequency: 2,
            email: undefined,
        });
    };
    const isFilterApplied = !isEmpty(country.value) ||
        !isEmpty(industry.value) ||
        !isEmpty(searchQuery);
    return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: "page-elements__actions container" }, { children: [_jsx("div", Object.assign({ className: "page-elements__actions__search" }, { children: _jsx(Searchbar, { searchQuery: [searchQuery, setSearchQuery], setSearchItem: setSearchItem, contentTypes: contentTypeIds }) })), _jsxs("div", Object.assign({ className: "page-elements__actions__filters" }, { children: [_jsx(Listbox.Autocomplete, { emptyOption: { label: 'Select Industry', value: undefined }, selected: industry, setSelected: (industry) => handleFilterChange(setIndustry, industry, 'industry'), isLoading: isFiltersLoading || isFiltersFetching, options: (_e = filtersData === null || filtersData === void 0 ? void 0 : filtersData.industries.map((item) => ({
                                    label: item.name,
                                    value: item.id,
                                    count: item.total,
                                    disabled: item.total === 0,
                                }))) !== null && _e !== void 0 ? _e : [] }), _jsx(Listbox.Autocomplete, { emptyOption: { label: 'Select Country', value: undefined }, selected: country, setSelected: (country) => handleFilterChange(setCountry, country, 'country'), isLoading: isFiltersLoading || isFiltersFetching, options: (_f = filtersData === null || filtersData === void 0 ? void 0 : filtersData.countries.map((item) => ({
                                    label: item.name,
                                    value: item.id,
                                    count: item.total,
                                    disabled: item.total === 0,
                                }))) !== null && _f !== void 0 ? _f : [] }), _jsxs("div", Object.assign({ className: "d-flex gap-4 align-items-end" }, { children: [_jsx(Link, Object.assign({ to: "?q=", reloadDocument: true, className: "text--bold text--t2" }, { children: "Clear filters" })), _jsx(Button, Object.assign({ color: "ghost-link", disabled: !isFilterApplied, onClick: handleSave }, { children: "Save as search alert" }))] }))] }))] })), _jsx("div", Object.assign({ className: "page-elements__results container" }, { children: sections.map((section) => {
                    var _a;
                    return (_jsx(ErrorBoundary, Object.assign({ fallback: _jsx(SubcategoryErrorComponent, { title: section.title }) }, { children: _jsx(Subcategory, { section: section, limit: preloadCount, searchQuery: (_a = searchItem === null || searchItem === void 0 ? void 0 : searchItem.name) !== null && _a !== void 0 ? _a : '', country: country.value, industry: industry.value, maxLoadCount: maxLoadCount }) }), section.url));
                }) })), notificationsOpen && (_jsx(ErrorBoundary, Object.assign({ FallbackComponent: DefaultErrorComponent }, { children: _jsx(Suspense, Object.assign({ fallback: null }, { children: _jsx(NotificationManagement, { open: notificationsOpen, onClose: () => setNotificationsOpen(false) }) })) })))] }));
};
export default SearchPage;
